import { ReactNode, useMemo, useState } from 'react';
import CountryList from 'utils/constants/ISOCodeCountry';
import * as S from '../styles/auth.styled';

interface Props {
  handleClose: () => void;
  isOpen: boolean;
  content: string;
  type?: string;
  actionHandler?: (country: string) => void;
  actionLabel?: string;
  withOutLabel?: boolean;
  withCheckbox?: boolean;
  checkBoxText?: ReactNode | ReactNode[];
  title?: string;
}

export default function Modal({
  title,
  handleClose,
  isOpen,
  content,
  type,
  actionHandler,
  actionLabel,
  withOutLabel,
  withCheckbox,
  checkBoxText,
}: Props) {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  // Store the full country name.
  const [country, setCountry] = useState('');

  // Validate if the entered country is exactly one of the valid options.
  const isValidCountry = CountryList.some((item) => item.name === country);

  if (!isOpen) return <></>;

  // Disable continue button if no country is selected, checkbox not checked, or if the country is invalid.
  const isDisabled = country === '' || !isChecked || !isValidCountry;

  return (
    <S.ModalOverlay>
      <S.ModalContent
        $type={type || ''}
        className="gap-[36px] md:w-[705px] w-full"
        $withCheckBox={!!withCheckbox}
      >
        <S.ModalTopBar>
          <img src="/images/cross.svg" alt="cross icon" onClick={handleClose} />
        </S.ModalTopBar>
        {!withOutLabel ? (
          <img
            src={`/images/checkmark${type ? `-${type}` : ''}.svg`}
            alt="checkmark icon"
          />
        ) : null}
        {title ? <h1 className="sm:text-[24px] text-[16px]">{title}</h1> : null}
        <span
          className={`text-[#0E0D0D] ${title === 'Terms & conditions' ? 'px-[50px]' : 'px-[10px]'
            } head-text font-[700]`}
        >
          {content}
        </span>
        {withCheckbox ? (
          <S.ModalCheckBox>
            <input
              className="md:mt-0 mt-[5px]"
              type="checkbox"
              onChange={(e) => setIsChecked(e.target.checked)}
              data-cy="terms-checkbox"
            />
            {checkBoxText}
          </S.ModalCheckBox>
        ) : null}
        <div className="w-full flex justify-start flex-col">
          <label className="mx-[50px] mb-3 head-text font-[700] text-[#0E0D0D]">
            Choose country
          </label>
          {/* Input with datalist for filtering */}
          <input
            list="countryList"
            data-cy="country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            onBlur={() => {
              // If the entered value is not a valid country, clear the input.
              if (!isValidCountry) {
                setCountry('');
              }
            }}
            className="create-brief-input select-input bg-[#f9fbfd] sm:w-[300px] mx-[50px] text-[#0E0D0D]"
            placeholder="Type to filter..."
          />
          <datalist id="countryList">
            {CountryList.map((item, index) => (
              <option key={index} value={item.name} />
            ))}
          </datalist>
        </div>
        {actionLabel && (
          <button
            data-cy="submit-button"
            className="creator-button text-[16px]"
            disabled={isDisabled}
            onClick={() => {
              actionHandler && actionHandler(country);
            }}
            style={{
              backgroundColor: isDisabled ? "#ccc" : "#007BFF", // Gray if disabled, blue if enabled
              cursor: isDisabled ? "not-allowed" : "pointer",
            }}
          >
            {actionLabel}
          </button>
        )}
      </S.ModalContent>
    </S.ModalOverlay>
  );
}
