import { BestPractices as IBestPractice } from 'API';
import { getActiveBestPractice } from 'hooks';
import { useState, useEffect } from 'react';
import './practice.css';
import PillarConstruction from 'components/BestPracticePillars/PillarConstruction';

export default function BestPractice() {
  const { getActivePractice, data, loading } = getActiveBestPractice();
  const [practices, setPractices] = useState<Array<IBestPractice | null>>([]);

  useEffect(() => {
    getActivePractice({ variables: { active: 'true' } });
  }, []);



  useEffect(() => {
    if (!loading && data?.[0]) setPractices(data);
  }, [data, loading]);

  const url = "https://user-storage91541-master.s3.us-east-1.amazonaws.com/public/watermarked_creatives/creative/31920124-0d0b-49c6-b840-a945acb0b4c4/4280391e-0b36-4535-a91e-5aee9efa2b76_compressed.MOV"


  const pillarsData =
    [
      {
        "pillar": 1,
        "mainHeading": "How to be an EDC content creator",
        "steps": [
          {
            "step": 1,
            "subHeading": "Introduction",
            "videoSrc": url,
            "keyPoints": [
              "EDC is different from traditional content creation",
              "Access to brands for anyone immediately",
              "Earn based on performance"
            ]
          },
          {
            "step": 2,
            "subHeading": "Getting Started",
            "videoSrc": "video_url_2",
            "keyPoints": [
              "Ensure profile details are correct",
              "Add a summary and tag words to describe yourself",
              "Upload previous content if available"
            ]
          },
          {
            "step": 3,
            "subHeading": "Navigating the Platform",
            "videoSrc": "video_url_3",
            "keyPoints": [
              "Main Dashboard Overview",
              "Understanding Brand Activations, Creatives, Wallet",
              "Why EDC is the answer"
            ]
          }
        ]
      },
      {
        "pillar": 2,
        "mainHeading": "How to start earning with EDC",
        "steps": [
          {
            "step": 1,
            "subHeading": "Brand Activations",
            "videoSrc": "video_url_4",
            "keyPoints": [
              "Each activation includes a video brief",
              "Do’s and Don’ts",
              "You must read all sections before submitting content"
            ]
          },
          {
            "step": 2,
            "subHeading": "The Submission Process",
            "videoSrc": "video_url_5",
            "keyPoints": [
              "Revisions are part of the process",
              "Feedback from the EDC team and clients",
              "Minimizing revisions for a smoother experience"
            ]
          },
          {
            "step": 3,
            "subHeading": "Earnings & Payments",
            "videoSrc": "video_url_6",
            "keyPoints": [
              "Approved content is promoted as ads on TikTok, Instagram, and YouTube Shorts",
              "Earnings are updated weekly",
              "Payouts happen in the first week of every month"
            ]
          },
          {
            "step": 4,
            "subHeading": "Maximizing Your Earnings",
            "videoSrc": "video_url_7",
            "keyPoints": [
              "Submit early",
              "Submit multiple pieces of content",
              "Work across multiple brands (avoid competitor conflicts)",
              "Follow activation details to minimize revisions"
            ]
          }
        ]
      },
      {
        "pillar": 3,
        "mainHeading": "How to shoot content",
        "steps": [
          {
            "step": 1,
            "subHeading": "Introduction to Shooting",
            "videoSrc": "video_url_8",
            "keyPoints": [
              "Start with a simple concept",
              "Experiment and have fun",
              "You don’t need expensive gear"
            ]
          },
          {
            "step": 2,
            "subHeading": "Camera Setup",
            "videoSrc": "video_url_9",
            "keyPoints": [
              "Use your phone for vertical videos",
              "Ideal resolution: 1080p (Full HD)",
              "Shoot in a 9:16 ratio for best results"
            ]
          },
          {
            "step": 3,
            "subHeading": "Lighting & Angles",
            "videoSrc": "video_url_10",
            "keyPoints": [
              "Choose a clean background",
              "Use a tripod or household items for stability",
              "Experiment with different angles (POV, high shot, tight shot, etc.)"
            ]
          },
          {
            "step": 4,
            "subHeading": "Audio Tips",
            "videoSrc": "video_url_11",
            "keyPoints": [
              "Record clear voiceovers",
              "Use non-copyright music",
              "Minimize background noise"
            ]
          }
        ]
      },
      {
        "pillar": 4,
        "mainHeading": "How to edit content",
        "steps": [
          {
            "step": 1,
            "subHeading": "Editing Software",
            "videoSrc": "video_url_12",
            "keyPoints": [
              "Recommended programs for beginners",
              "Editing on mobile vs desktop",
              "Essential editing tools"
            ]
          },
          {
            "step": 2,
            "subHeading": "Safe Zones & Layout",
            "videoSrc": "video_url_13",
            "keyPoints": [
              "Avoid placing text too close to edges",
              "Use overlays to guide placement",
              "Keep the focus on the subject"
            ]
          },
          {
            "step": 3,
            "subHeading": "Transitions & Flow",
            "videoSrc": "video_url_14",
            "keyPoints": [
              "Smooth transitions between clips",
              "How to use jump cuts effectively",
              "Maintaining engagement with pacing"
            ]
          },
          {
            "step": 4,
            "subHeading": "Subtitles & Accessibility",
            "videoSrc": "video_url_15",
            "keyPoints": [
              "Use readable fonts",
              "Ensure correct placement",
              "Enhancing accessibility for wider reach"
            ]
          }
        ]
      }
    ]




  return (
    <>
      {/* Pillar Animation Overlay */}
      <PillarConstruction pillars={pillarsData} />
    </>
  );
}
