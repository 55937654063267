import { USER_TYPES } from 'API';
import { Auth } from 'aws-amplify';
import AdminDashboard from 'pages/adminDashboard/adminDashboard';
import BrandDashboard from 'pages/brandDashboard/brandDashboard';
import CreatorDashboard from 'pages/creatorDashboard/creatorDashboard';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { withProfile } from 'state/profileSteps';
import { ProfileProps, UnAuthRoutes } from 'utils';
import Modal from '../../components/authentication/TermsAndConditions/modal';

const Dashboard: React.FC<ProfileProps> = ({
  profileState: { data },
  editCreatorProfile,
}) => {
  const navigate = useNavigate();
  const [isShowModal, setIsShowModal] = useState(false);

  useEffect(() => {
    if (data?.userType === USER_TYPES.CREATIVE_USER) {

      if (!data.termsAndConditions || data.country === null) {
        const hasAgreed = localStorage.getItem(`agreementConfirmed_${data.id}`);
        if (!hasAgreed) {
          setIsShowModal(true);
        }
      }
    }
  }, [data]);

  const onConfirm = (country: string) => {
    localStorage.setItem(`agreementConfirmed_${data?.id}`, 'true');
    editCreatorProfile({ country, termsAndConditions: true });
    setIsShowModal(false);
  };

  if (data?.userType === USER_TYPES.CREATIVE_USER && isShowModal) {
    return (
      <Modal
        withOutLabel
        withCheckbox
        isOpen={isShowModal}
        title={'Terms & conditions'}
        content="You must agree to terms and conditions to continue."
        handleClose={async () => {
          await Auth.signOut();
          navigate(UnAuthRoutes.Login);
        }}
        checkBoxText={
          <p>
            I agree to the{' '}
            <span
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_FRONTEND_BASE_URL}termsAndConditions`,
                  '_blank'
                );
              }}
            >
              terms and conditions
            </span>
          </p>
        }
        type="creator"
        actionLabel="CONFIRM"
        actionHandler={onConfirm}
      />
    );
  }


  if (data?.userType === USER_TYPES.CREATIVE_USER)
    return <CreatorDashboard data={data} />;
  if (data?.userType === USER_TYPES.BRAND_USER) return <BrandDashboard />;
  if (data?.userType === USER_TYPES.ADMIN_USER) return <AdminDashboard />;

  return <Fragment />;
};

export default withProfile(Dashboard);
