import { DialogContent } from "@mui/material";

interface VideoDialogProps {
    videoSrc: string;
}

const VideoDialog: React.FC<VideoDialogProps> = ({ videoSrc }) => {
    const isSmallScreen = window.innerWidth <= 768;

    const baseWidth = isSmallScreen ? 240 : window.innerWidth <= 1024 ? 270 : 420;
    const baseHeight = isSmallScreen ? 400 : window.innerWidth <= 1024 ? 470 : 820;

    // Reduce size by 20% on small screens
    const reducedWidth = isSmallScreen ? baseWidth * 0.90 : baseWidth * 0.7;
    const reducedHeight = isSmallScreen ? baseHeight * 0.98 : baseHeight * 0.7;

    return (
        <DialogContent
            className="relative flex justify-center items-center p-0"
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "transparent",
                overflow: "hidden",
            }}
        >
            <div
                className="relative rounded-3xl"
                style={{
                    position: "relative",
                    borderRadius: "24px",
                    backgroundColor: "transparent",
                    overflow: "hidden",
                    height: `${reducedHeight}px`,
                    width: `${reducedWidth}px`,
                }}
            >
                <video
                    autoPlay
                    controls
                    className="absolute top-0 left-0"
                    style={{
                        width: "90%",
                        height: "96%",
                        objectFit: "cover",
                        zIndex: 1,
                        marginTop: isSmallScreen ? "7px" : "12px",
                        marginLeft: isSmallScreen ? "11px" : "15px",
                        borderRadius: isSmallScreen ? "20px" : "34px",
                    }}
                >
                    <source src={videoSrc} type="video/mp4" />
                </video>

                <img
                    src="/images/iPhone-bg.png"
                    className="absolute top-0 left-0 w-full h-full pointer-events-none"
                    style={{ zIndex: 2 }}
                    alt="iPhone frame"
                />
            </div>
        </DialogContent>
    );
};

export default VideoDialog;
