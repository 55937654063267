import { FileDownload, OpenInNew, Search } from '@mui/icons-material';
import { CircularProgress, IconButton, InputAdornment } from '@mui/material';
import { GridRowParams, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import {
  ADMIN_STATUS,
  CreativeRequest,
  CREATIVE_REQUEST_LAST_UPDATED_BY,
  EMAIL_TYPE,
  ENTITIES,
  SortOrder,
  USER_TYPES,
  CREATIVE_STATUS,
} from 'API';
import { downloadFileFromUrl } from 'components';
import { AdminApprovalContent } from 'components/adAdminApproval/Index';
import Modal from 'components/authentication/modal';
import { TABLE_HEADER_CELLS } from 'components/BrandCreativeRequests/constants';
import { StyledDataGrid } from 'components/DataGrid';
import { StyledTextField } from 'components/TextField';
import { UpdateCreativeRequest, UseSearchCreativeRequests } from 'hooks';
import { UseExportEntitiesToCSV } from 'hooks/query/useAdminActions';
import { useRequestStatusSendEmail } from 'hooks/query/useEmail';
import {
  DATA_GRID_ROW_OPTIONS,
  DEFAULT_DEBOUNCE_TIME_IN_MILLISECONDS,
} from 'hooks/utils';
import { debounce } from 'lodash';
import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ViewRequestProps, withRequestView } from 'state/requests';
import { AdminRoutes } from 'utils';
import { getSubmissionRowColor, TABLE_HEADER } from '../constants';

interface SortingPayload {
  sortKey: string;
  sortOrder: SortOrder;
}

interface ITableTabProps {
  reqloading: boolean;
  creativeRequestsData: CreativeRequest[];
}

const TableTab: FC<ITableTabProps & ViewRequestProps> = ({ getVideoLink }) => {
  // Local state
  const [filteredData, setFilteredData] = useState<CreativeRequest[]>([]);
  const [showInspiration, setShowInspiration] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedCreativeRequest, setSelectedCreativeRequest] = useState<CreativeRequest | null>(null);
  const [search, setSearch] = useState<string>('');
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
  const [sortingModel, setSortingModel] = useState<SortingPayload>({
    sortKey: 'updatedAt',
    sortOrder: SortOrder.desc,
  });

  // Navigation and URL
  const navigate = useNavigate();
  const location = useLocation();

  // Hooks for queries and actions
  const { updateRequest, loading: updateCreativeRequestLoading } = UpdateCreativeRequest();
  const { sendEmailData, loading: sendStatusEmailLoading } = useRequestStatusSendEmail();
  const { exportEntities, url: exportUrl, loading: exportEntitiesLoading } = UseExportEntitiesToCSV();
  const { searchRequests, requests, totalItems, loading: searchLoading } = UseSearchCreativeRequests();

  // Determine if details view is open via URL search parameters.
  const isDetailsViewOpen = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.has('content');
  }, [location.search]);

  /**
   * Fetch creative requests using provided or current parameters.
   */
  const getCreativeRequests = useCallback(
    ({
      page,
      pageSize,
      search: searchParam,
      sortKey,
      sortOrder,
    }: {
      page?: number | null;
      pageSize?: number | null;
      search?: string | null;
      sortKey?: string | null;
      sortOrder?: SortOrder | null;
    } = {}) => {
      searchRequests({
        variables: {
          search: searchParam ?? search,
          page: page ?? paginationModel.page,
          pageSize: pageSize ?? paginationModel.pageSize,
          sortKey: sortKey ?? sortingModel.sortKey,
          sortOrder: sortOrder ?? sortingModel.sortOrder,
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
      });
    },
    [searchRequests, search, paginationModel, sortingModel]
  );

  // Initial data fetch on component mount.
  useLayoutEffect(() => {
    getCreativeRequests({
      search,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sortKey: sortingModel.sortKey,
      sortOrder: sortingModel.sortOrder,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update local filtered data when requests change.
  useEffect(() => {
    setFilteredData((requests as CreativeRequest[]) || []);
  }, [requests]);

  // Download file when export URL is ready.
  useEffect(() => {
    if (exportUrl) {
      downloadFileFromUrl(exportUrl);
    }
  }, [exportUrl]);

  /**
   * Handle row click to show details.
   */
  const onRowClick = useCallback(
    (req: CreativeRequest) => {
      setSelectedCreativeRequest(req);
      setShowInspiration(true);
      navigate(`${AdminRoutes.CreativeApproval}?content=true`);
    },
    [navigate]
  );

  /**
   * Refresh data after a successful action.
   */
  const onSuccess = useCallback(() => {
    getCreativeRequests({
      search,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sortKey: sortingModel.sortKey,
      sortOrder: sortingModel.sortOrder,
    });
  }, [getCreativeRequests, search, paginationModel, sortingModel]);

  /**
   * Update creative request status and send email (if needed).
   */
  const updateCreativeRequestStatus = useCallback(
    async (
      newStatus: ADMIN_STATUS,
      comment?: string,
      sendEmail?: boolean,
      previousStatus?: string
    ) => {
      if (!selectedCreativeRequest) return;

      const isApproved = newStatus === ADMIN_STATUS.Approved;
      const adminComment = [
        ...(selectedCreativeRequest.adminComment || []),
        comment || '',
      ];

      const updateBrandStatus =
        previousStatus === ADMIN_STATUS.Revision ? CREATIVE_STATUS.Submitted : '';

      await updateRequest({
        variables: {
          input: {
            id: selectedCreativeRequest.id,
            adminApproval: ADMIN_STATUS[newStatus],
            lastUpdatedBy: CREATIVE_REQUEST_LAST_UPDATED_BY.ADMIN,
            ...(updateBrandStatus && { status: updateBrandStatus }),
            ...(!isApproved && { adminComment }),
          },
        },
        errorPolicy: 'ignore',
      });

      if (sendEmail) {
        const emailTypeByAdminStatus = isApproved
          ? EMAIL_TYPE.ADMIN_APPROVED
          : ADMIN_STATUS[newStatus].toUpperCase();

        const emailToSend = isApproved
          ? selectedCreativeRequest?.brief?.brandProfile?.userEmail
          : selectedCreativeRequest.email;

        await sendEmailData({
          variables: {
            creativeRequestUrl: `creatives?id=${selectedCreativeRequest.id}`,
            email: emailToSend,
            name: selectedCreativeRequest?.creatorProfile?.name,
            brandBriefName: selectedCreativeRequest.BriefName,
            creativeUniqueId: selectedCreativeRequest.uniqueId,
            emailType: emailTypeByAdminStatus,
            brandBriefId: selectedCreativeRequest.brandBriefId,
            feedback: comment || '',
          },
        });
      }

      setShowSuccessModal(true);
      onSuccess();
    },
    [selectedCreativeRequest, updateRequest, sendEmailData, onSuccess]
  );

  /**
   * Configure table headers, keeping your current style and headings.
   */
  const tableHead = useMemo(
    () =>
      TABLE_HEADER.map((c) => {
        if (c.field === 'details') {
          return {
            ...c,
            renderCell: (params: any) => (
              <div className="h-full flex justify-center items-center">
                <IconButton
                  className="w-[25px] h-[25px] p-[20px]"
                  onClick={() => onRowClick(params.row)}
                >
                  <OpenInNew />
                </IconButton>
              </div>
            ),
          };
        }
        return c;
      }),
    [onRowClick, TABLE_HEADER_CELLS]
  );

  /**
   * Handle sorting changes.
   */
  const onSortModelChange = useCallback(
    (model?: GridSortItem) => {
      if (!model) return;
      const updatedSortModel = {
        sortKey: model.field || sortingModel.sortKey,
        sortOrder: (model.sort || sortingModel.sortOrder) as SortOrder,
      };
      setSortingModel(updatedSortModel);
      getCreativeRequests({
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        search,
        ...updatedSortModel,
      });
    },
    [sortingModel.sortKey, sortingModel.sortOrder, paginationModel, search, getCreativeRequests]
  );

  /**
   * Debounced search callback.
   */
  const onSearch = useMemo(
    () =>
      debounce((text: string) => {
        const trimmed = text.trim();
        setPaginationModel((prev) => ({ ...prev, page: 0 }));
        setSortingModel({ sortKey: 'updatedAt', sortOrder: SortOrder.desc });
        getCreativeRequests({ search: trimmed });
        setSearch(trimmed);
      }, DEFAULT_DEBOUNCE_TIME_IN_MILLISECONDS),
    [getCreativeRequests]
  );

  /**
   * Export creative requests.
   */
  const onExport = useCallback(async () => {
    await exportEntities({ variables: { entity: ENTITIES.CreativeRequest } });
  }, [exportEntities]);

  /**
   * Handle pagination changes.
   */
  const handlePaginationModelChange = useCallback(
    (newPaginationModel) => {
      if (searchLoading) return;

      const updatedPaginationModel = {
        page:
          newPaginationModel.pageSize !== paginationModel.pageSize
            ? 0
            : newPaginationModel.page,
        pageSize: newPaginationModel.pageSize,
      };

      setPaginationModel(updatedPaginationModel);
      getCreativeRequests({
        ...updatedPaginationModel,
        search,
        sortKey: sortingModel.sortKey,
        sortOrder: sortingModel.sortOrder,
      });
    },
    [paginationModel.pageSize, search, sortingModel, getCreativeRequests, searchLoading]
  );

  // If details view is active, render it.
  if (showInspiration && selectedCreativeRequest && isDetailsViewOpen) {
    return (
      <>
        <AdminApprovalContent
          videoUrl={selectedCreativeRequest?.tiktokCreativeUrl || ''}
          onClose={() => setShowInspiration(false)}
          request={selectedCreativeRequest}
          getVideoLink={getVideoLink}
          createAdPayload={{}}
          onSuccess={onSuccess}
          updateCreativeRequestStatus={updateCreativeRequestStatus}
          type="Admin"
          reqLoading={updateCreativeRequestLoading || sendStatusEmailLoading}
        />
        <Modal
          isOpen={showSuccessModal}
          handleClose={() => {
            setShowInspiration(false);
            setShowSuccessModal(false);
          }}
          type="brand"
          content="The Status of the creative request was successfully changed"
        />
      </>
    );
  }

  // Render the main table view.
  return (
    <div className="md:col-span-2">
      <div className="md:flex items-center mb-4">
        <section className="flex gap-4 w-full items-center justify-between mt-6">
          <div>
            <StyledTextField
              variant="standard"
              placeholder="Search..."
              onChange={(e) => onSearch(e.target.value)}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div>
            <button
              className="bg-main-black text-white px-[22px] py-[4] w-[200px] h-[42px] rounded-[16px] text-[12px] disabled:bg-neutral-400 break-keep text-wrap"
              disabled={!requests?.length}
              onClick={onExport}
            >
              <div className="flex justify-evenly uppercase font-semibold items-center gap-x-1">
                {exportEntitiesLoading ? (
                  <CircularProgress className="text-white w-6 h-6" />
                ) : (
                  <>
                    <FileDownload />
                    <span>Export Creative Requests</span>
                  </>
                )}
              </div>
            </button>
          </div>
        </section>
      </div>

      <div className="overflow-x-auto max-w-full">
        <StyledDataGrid
          rows={filteredData || []}
          sx={{ minHeight: 600 }}
          density="comfortable"
          columns={tableHead}
          pageSizeOptions={DATA_GRID_ROW_OPTIONS}
          onPaginationModelChange={handlePaginationModelChange}
          paginationModel={paginationModel}
          onSortModelChange={(model: GridSortModel) => onSortModelChange(model?.[0])}
          rowCount={totalItems || 0}
          autosizeOptions={{
            expand: true,
            includeHeaders: true,
            includeOutliers: true,
          }}
          getRowClassName={(params) => {
            const { lastUpdatedBy, adminApproval } = params.row;
            return getSubmissionRowColor({
              lastUpdatedBy,
              adminApproval,
              userType: USER_TYPES.ADMIN_USER,
            });
          }}
          onRowClick={({ row }: GridRowParams) => onRowClick(row)}
          sortingMode="server"
          paginationMode="server"
          disableColumnResize
          disableRowSelectionOnClick
          disableAutosize
          disableColumnMenu
          autosizeOnMount
          disableColumnSelector
        />
        {searchLoading && (
          <div
            className="flex justify-center items-center"
            style={{ position: 'relative', top: '-500px' }}
          >
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default withRequestView(TableTab);
