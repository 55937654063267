import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import PillarProgress from "./PillarProgress";
import VideoDialog from "./VideoDialog";

interface StepData {
    step: number;
    subHeading: string;
    videoSrc: string;
    keyPoints: string[];
}

interface PillarData {
    pillar: number;
    mainHeading: string;
    steps: StepData[];
}

interface PillarConstructionProps {
    pillars: PillarData[];
}

const PillarConstruction: React.FC<PillarConstructionProps> = ({ pillars }) => {
    const [currentPillarIndex, setCurrentPillarIndex] = useState(0);
    const [currentStepIndex, setCurrentStepIndex] = useState(0);

    const currentPillar = pillars[currentPillarIndex] ?? {
        pillar: 1,
        mainHeading: "Loading...",
        steps: [{ step: 1, subHeading: "Loading...", videoSrc: "", keyPoints: [] }],
    };

    const currentStep = currentPillar.steps[currentStepIndex] ?? {
        step: 1,
        subHeading: "Loading...",
        videoSrc: "",
        keyPoints: [],
    };

    const nextStep = () => {
        if (currentStepIndex < currentPillar.steps.length - 1) {
            setCurrentStepIndex((prev) => prev + 1);
        }
    };

    const nextPillar = () => {
        if (currentPillarIndex < pillars.length - 1 && currentStepIndex === currentPillar.steps.length - 1) {
            setCurrentPillarIndex((prev) => prev + 1);
            setCurrentStepIndex(0);
        }
    };

    const prevStep = () => {
        if (currentStepIndex > 0) {
            setCurrentStepIndex((prev) => prev - 1);
        }
    };

    const prevPillar = () => {
        if (currentPillarIndex > 0) {
            setCurrentPillarIndex((prev) => prev - 1);
            const previousPillar = pillars[currentPillarIndex - 1];
            setCurrentStepIndex(previousPillar ? previousPillar.steps.length - 1 : 0);
        }
    };

    return (
        <div className="flex flex-col items-center w-full">
            {/* Pillar Progress Navigation */}
            <PillarProgress
                currentStep={currentPillarIndex + 1}
                totalSteps={pillars.length}
                onNext={nextPillar}
                onPrev={prevPillar}
                pillarHeading={`Pillar ${currentPillar.pillar}: ${currentPillar.mainHeading}`}
                isLastStep={currentStepIndex === currentPillar.steps.length - 1}
            />

            {/* Step Heading */}
            <motion.h2
                key={currentStepIndex}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5, ease: "easeOut" }}
                className="text-3xl font-bold text-center mt-4 mb-6 font-oswald"
            >
                {currentStep.subHeading} (Step {currentStep.step}/{currentPillar.steps.length})
            </motion.h2>

            {/* Video + Key Points Container (Adjusts Position Based on Screen Size) */}
            <div className="relative flex flex-col md:flex-row items-center w-full max-w-[800px] px-4">

                {/* Key Points (LEFT - Hidden on Small Screens) */}
                <AnimatePresence>
                    {currentStep.keyPoints.length > 0 && (
                        <div className="hidden md:flex flex-col items-end gap-4 w-[200px]">
                            {currentStep.keyPoints.slice(0, 2).map((point, index) => (
                                <motion.div
                                    key={point}
                                    initial={{ opacity: 0, scale: 0.6 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    exit={{ opacity: 0, scale: 0.3 }}
                                    transition={{
                                        duration: 0.6,
                                        ease: [0.34, 1.56, 0.64, 1],
                                        delay: index * 0.2,
                                    }}
                                    className="bg-[#FF872F] text-white p-4 rounded-lg shadow-md flex items-center justify-center text-center font-oswald font-bold text-lg"
                                    style={{ width: "200px", height: "140px" }}
                                >
                                    {point}
                                </motion.div>
                            ))}
                        </div>
                    )}
                </AnimatePresence>

                {/* Video Player */}
                <VideoDialog videoSrc={currentStep.videoSrc} />

                {/* Key Points (RIGHT - Hidden on Small Screens) */}
                <AnimatePresence>
                    {currentStep.keyPoints.length > 2 && (
                        <div className="hidden md:flex flex-col items-start gap-4 w-[200px]">
                            {currentStep.keyPoints.slice(2, 4).map((point, index) => (
                                <motion.div
                                    key={point}
                                    initial={{ opacity: 0, scale: 0.6 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    exit={{ opacity: 0, scale: 0.3 }}
                                    transition={{
                                        duration: 0.6,
                                        ease: [0.34, 1.56, 0.64, 1],
                                        delay: index * 0.2,
                                    }}
                                    className="bg-[#FF872F] text-white p-4 rounded-lg shadow-md flex items-center justify-center text-center font-oswald font-bold text-lg"
                                    style={{ width: "200px", height: "140px" }}
                                >
                                    {point}
                                </motion.div>
                            ))}
                        </div>
                    )}
                </AnimatePresence>
            </div>

            {/* Key Points (Under Video on Small Screens) */}
            <div className="md:hidden flex flex-col items-center gap-4 mt-4 w-full">
                <AnimatePresence>
                    {currentStep.keyPoints.map((point, index) => (
                        <motion.div
                            key={point}
                            initial={{ opacity: 0, scale: 0.6 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.3 }}
                            transition={{
                                duration: 0.6,
                                ease: [0.34, 1.56, 0.64, 1],
                                delay: index * 0.2,
                            }}
                            className="bg-[#FF872F] text-white p-4 rounded-lg shadow-md flex items-center justify-center text-center font-oswald font-bold text-lg w-[80%] max-w-[300px] h-[90px]"
                        >
                            {point}
                        </motion.div>
                    ))}
                </AnimatePresence>
            </div>

            {/* Step Navigation */}
            <div className="flex justify-between w-full max-w-[400px] mt-6">
                <button
                    className={`p-3 rounded-full ${currentStepIndex === 0 && currentPillarIndex === 0
                        ? "opacity-50 cursor-not-allowed"
                        : "bg-gray-200"
                        }`}
                    onClick={prevStep}
                    disabled={currentStepIndex === 0 && currentPillarIndex === 0}
                >
                    ◀
                </button>

                {currentStepIndex < currentPillar.steps.length - 1 ? (
                    <button className="p-3 rounded-full bg-gray-200" onClick={nextStep}>
                        ▶
                    </button>
                ) : (
                    <button
                        className={`p-3 rounded-full ${currentPillarIndex === pillars.length - 1
                            ? "opacity-50 cursor-not-allowed"
                            : "bg-blue-600 hover:bg-blue-500"
                            } transition-all`}
                        onClick={nextPillar}
                        disabled={currentPillarIndex === pillars.length - 1}
                    >
                        Next Pillar ▶
                    </button>
                )}
            </div>
        </div>
    );
};

export default PillarConstruction;
