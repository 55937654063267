import { BrandBrief } from 'API';
import { Storage } from 'aws-amplify';
import { IconLoader } from 'components/loader';
import Input from 'components/ui/input';
import Label from 'components/ui/label';
import Select from 'components/ui/select';
import Spinner from 'components/ui/spinner';
import Switch from 'components/ui/switch';
import TextArea from 'components/ui/textArea';
import { createCampaignBrief, editCampaignBrief, GetBrandList } from 'hooks';
import useZodForm from 'hooks/useZodForm';
import {
  BrandBriefFirstStepSchema,
  ManualDataDefaultValues,
  MetaDataDefaultValues,
  TiktokDataDefaultValues,
  TOAST_CONFIG,
  YoutubeDataDefaultValues,
} from 'hooks/utils';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrandRoutes } from 'utils';
import CountryList from 'utils/constants/ISOCodeCountry';
import { v4 as uuid } from 'uuid';
import init from 'zod-empty';
import { ProfileContext } from '../../state/profileSteps';

function BriefForm() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { profileState } = useContext(ProfileContext);

  const [loading, setLoading] = useState(false);
  const [id, setId] = useState<string>();

  const { getBrandList, data: brandList } = GetBrandList();
  const isCreating = useMemo(() => !state?.brief?.id, [state]);

  const {
    editBrief,
    loading: editLoading,
    data: editData,
  } = editCampaignBrief();

  const {
    createBrief,
    loading: createLoading,
    data: createData,
  } = createCampaignBrief();

  const { brief = null }: { brief: BrandBrief | null } = state || {};

  const defaultValues = {
    ...init(BrandBriefFirstStepSchema),
    active: true,
  };

  const {
    watch,
    register,
    control,
    getValues,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useZodForm({
    schema: BrandBriefFirstStepSchema,
    defaultValues: brief || defaultValues,
    mode: 'onSubmit',
  });

  useEffect(() => {
    setId(brief?.id || uuid());
  }, [brief]);

  useEffect(() => {
    const inspirations = brief?.creativeInspirations;
    if (!inspirations?.length) {
      return;
    }

    inspirations.forEach((inspirationUrl, index) => {
      setValue(`creativeInspirations.${index}`, inspirationUrl);
    });
  }, [brief?.creativeInspirations]);

  const CountryOptions = useMemo(
    () =>
      CountryList.map(({ name: text, code: value, ...rest }) => ({
        text,
        value,
        ...rest,
      })),
    [CountryList]
  );

  const onUploadFiles = async (files) => {
    if (!brief?.id) {
      setId(uuid());
    }

    if (!files.length) {
      return;
    }

    const file = files?.[0];
    setLoading(true);

    const key = `brand/brief/${id}/${id}-brand-files.zip`;
    await Storage.put(key, file, {
      contentType: 'application/octet-stream',
      level: 'public',
      acl: 'public-read',
    });

    toastSuccess('File uploaded successfully!');
    setValue('brandBriefFilesUrl', key);
    setLoading(false);
  };

  const chooseContentvideos = async (files?: FileList | null) => {
    if (!files?.length) {
      return;
    }
    setLoading(true);

    const updatedId = brief?.id || uuid();
    if (id !== updatedId) {
      setId(id);
    }

    const videoUrls: string[] = [];
    const creativeInspirations = brief?.creativeInspirations || [];

    for (const file of files) {
      const fileName = file.name.split('.').pop();
      const url = `inspiration/${updatedId}/${uuid()}.${fileName}`;

      await Storage.put(url, file, {
        level: 'public',
        acl: 'public-read',
      });
      videoUrls.push(url);
    }

    [...videoUrls, ...creativeInspirations].map((url, i) =>
      setValue(`creativeInspirations.${i}`, url)
    );
    setLoading(false);
    toastSuccess(
      `Creative inspiration${files.length > 1 && 's'} uploaded successfully!`
    );
  };

  const toastSuccess = (message: string) => {
    toast.success(message, { ...TOAST_CONFIG });
  };

  const onSubmit = handleSubmit(async (data) => {
    const brand = profileState.data?.brand?.items?.[0];
    if (!brand) {
      toast.error('Please setup your brand account');
      return;
    }

    const payload = {
      ...data,
      brandId: brand.id,
      id: brief?.id || id || '',
      creativeInspirations: data.creativeInspirations,
      vertical: profileState.data?.vertical || '',
      brandBriefFilesUrl: data.brandBriefFilesUrl || '',
      whitelist: [],
      ...(brand.name && { brandName: brand.name }),
      TargetAudience: data.TargetAudience,
    };
    console.log(payload)

    if (isCreating) {
      delete payload['brandProfile'];
      delete payload['owner'];
      await createBrief({
        variables: {
          input: {
            ...payload,
            type: 'BrandBrief',
            manualData: ManualDataDefaultValues,
            metaData: MetaDataDefaultValues,
            youtubeData: YoutubeDataDefaultValues,
            tikTokData: TiktokDataDefaultValues,
          },
        },
        errorPolicy: 'ignore',
      });

      setLoading(false);
      return;
    }

    await editBrief({
      variables: {
        input: payload,
      },
      errorPolicy: 'ignore',
    });
  });

  useEffect(() => {
    if (createData || editData) {
      navigate(BrandRoutes.BriefFormStep2, {
        state: { brief: createData || editData },
      });
    }
  }, [createData, editData]);

  useEffect(() => {
    if (!brandList) {
      getBrandList();
      return;
    }
  }, [brandList]);

  return (
    <>
      <section className="bg-white py-[20px] rounded-[16px]">
        <h1 className="text-[#0E0D0D] uppercase ml-6 head-text text-[16px] font-[700]">
          {brief ? 'Edit' : 'Create'} Activation
        </h1>
        {!editLoading && !createLoading ? (
          <form onSubmit={onSubmit}>
            <div className="grid xl:grid-cols-2 p-6 xl:gap-8">
              <div className="xl:col-span-1 col-span-3">
                <Input
                  required
                  placeholder="Give your brief a name"
                  name="BriefName"
                  label="Activation Name"
                  register={register}
                  className="mb-5"
                  errors={errors}
                  dataCy="briefName"
                />
                <Select
                  required
                  name="country"
                  label="Country *"
                  placeholder="Select a country"
                  options={CountryOptions}
                  control={control}
                  errors={errors}
                  dataCy="country"
                />
                <Input
                  required
                  name="objective"
                  label="Objective"
                  register={register}
                  errors={errors}
                  dataCy="objective"
                />
                { <Input
                  required
                  name="TargetAudience"
                  label="Target Audience"
                  register={register}
                  errors={errors}
                  dataCy="targetAudience"
                /> }
                <Switch
                  name="active"
                  label="Active"
                  control={control}
                  required
                />
              </div>

              <div className="xl:col-span-1 col-span-3 rounded-lg">
                <>
                  <div
                    className={`${(getValues('creativeInspirations')?.length || 0) &&
                      'h-[279px] overflow-y-auto'
                      }`}
                  >
                    <Label name="Creative inspiration" />
                    {watch('creativeInspirations') &&
                      getValues('creativeInspirations')?.map((_, index) => (
                        <Input
                          key={index}
                          name={`creativeInspirations.${index}`}
                          className={`${index === 0 ? 'm-0' : 'mt-[23px]'}`}
                          placeholder="Creative inspiration URL"
                          inputClassName="bg-white"
                          label=""
                          register={register}
                          errors={errors}
                        />
                      ))}
                  </div>

                  <div className="flex justify-end gap-3 mb-3">
                    {loading && (
                      <div>
                        <Spinner className="w-8 h-8 mt-6" />
                      </div>
                    )}
                    <div>
                      <input
                        id="zip-upload"
                        type="file"
                        className="hidden"
                        accept=".zip, .tar, .7z, .rar, .gz"
                        onChange={(e) => onUploadFiles(e.target.files)}
                      />
                      <label className="flex justify-center">
                        <label
                          htmlFor="zip-upload"
                          className="creator-button cursor-pointer text-[14px] mt-[20px]"
                        >
                          <h6>Upload files</h6>
                        </label>
                      </label>
                    </div>

                    <div>
                      <input
                        id="file-upload"
                        type="file"
                        className="hidden"
                        multiple
                        accept="video/*, .mov"
                        onChange={(e) => chooseContentvideos(e.target.files)}
                      />
                      <label className="flex justify-center">
                        <label
                          htmlFor="file-upload"
                          className="creator-button cursor-pointer text-[14px] mt-[20px]"
                        >
                          <h6>Upload creative inspiration</h6>
                        </label>
                      </label>
                    </div>
                  </div>
                </>
              </div>
            </div>

            <div className="grid xl:grid-cols-2 grid-cols-1">
              <TextArea
                required
                className="pb-6 px-6"
                rows={9}
                name="brandBriefDetails"
                label="Activation details"
                placeholder="Decsribe your brief in more detail..."
                register={register}
                errors={errors}
                dataCy="brandBriefDetails"
              />
              <TextArea
                required
                className="pb-6 px-6"
                rows={9}
                name="adText"
                label="Ad text / Caption"
                placeholder="Decsribe your brief in more detail..."
                register={register}
                errors={errors}
                dataCy="adText"
              />
            </div>
            <div className="xl:hidden w-full px-6 pb-6"></div>
            <div
              className="
        flex sm:flex-row w-full sm:justify-center
        font-sans text-base text-white font-bold flex-col-reverse gap-4 items-center px-6"
            >
              <button
                type="submit"
                className="creator-button disabled:bg-[#a8a8a8]"
                data-cy="go-next-step"
              >
                Next
              </button>
            </div>
          </form>
        ) : (
          <div className="loader-content h-[calc(100vh-120px)]">
            <IconLoader />
          </div>
        )}
      </section>
    </>
  );
}

export default BriefForm;
