import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Tab, Typography } from '@mui/material';
import { useState } from 'react';
import { withBrand } from 'state/brand';
import { withProfile } from 'state/profileSteps';
import EverydayContent from './EverydayContent';

enum BrandLoginViewTabs {
  EverydayContent = 'Everyday content',
}

const BrandLoginView = ({ profileState, ...rest }): JSX.Element => {
  const [tab, setTab] = useState<BrandLoginViewTabs>(
    BrandLoginViewTabs.EverydayContent
  );

  const onChange = (_, tab) => {
    setTab(tab);
  };

  return (
    <div className="flex flex-col justify-center items-center py-6 px-2 sm:px-6 gap-y-10 min-h-[100vh]">
      <div className="w-full flex justify-between items-center">
        <div className="h-[60px] w-[185px] text-left">
          <img src="/images/preview-page-logo.svg" alt="EDCSquared" />
        </div>

        <div className="justify-self-center text-center align-middle mx-auto ">
          <Typography className="uppercase font-bold font-oswald text-[22px] mr-[120px]">
            UNLOCKING THE POWER OF EVERYDAY CONTENT
          </Typography>
        </div>
      </div>

      <div className="h-full w-full sm:border-2 sm:border-gray-main sm:rounded-[16px] flex grow">
        <div className="w-full flex flex-col gap-y-10 min-w-[367px]">
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                TabIndicatorProps={{ className: 'bg-tab-main-teal' }}
                onChange={onChange}
              >
                <Tab
                  disableRipple={true}
                  className={`${tab === BrandLoginViewTabs.EverydayContent
                    ? 'text-tab-main-teal'
                    : 'text-gray-300'
                    } head-text font-[700] border-b-2 border-b-black border-solid`}
                  label={BrandLoginViewTabs.EverydayContent}
                  value={BrandLoginViewTabs.EverydayContent}
                />
              </TabList>
            </Box>

            <TabPanel sx={{ p: 0 }} value={BrandLoginViewTabs.EverydayContent}>
              <EverydayContent />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </div>
  );
};

export default withProfile(withBrand(BrandLoginView));
