import { zodResolver } from '@hookform/resolvers/zod';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Button, Tab } from '@mui/material';
import { BrandBrief } from 'API';
import classNames from 'classnames';
import { AdminBriefsAccess } from 'components/AdminBriefAccess/AdminBriefsAccess';
import { UseUpdateBrandBrief } from 'hooks';
import { FC, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withAdmin } from 'state/admin';
import { MainTabSchema } from '../schema/index';
import MainTab from './components/MainTab/MainTab';
import { Tabs } from './constants';

export interface IFirstPageProps {
  brandBrief: BrandBrief;
  stageNext: () => void;
}

export interface IPractices {
  goodPractices: string[];
  badPractices: string[];
  maybes: string[];
  commonRejectionReasons: string[];
}

const FirstPage: FC<IFirstPageProps> = ({ brandBrief, stageNext }) => {
  const { updateBrief, loading: updateBriefLoading } = UseUpdateBrandBrief();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.Activation);
  const [practicesState, setPracticesState] = useState<IPractices>({
    goodPractices: brandBrief?.goodPractices || [],
    badPractices: brandBrief?.badPractices || [],
    maybes: brandBrief?.maybes || [],
    commonRejectionReasons: brandBrief?.commonRejectionReasons || [],
  });

  const methods = useForm({
    resolver: zodResolver(MainTabSchema),
    defaultValues: {
      ...brandBrief,
      tags: brandBrief?.tags || [],
      overviewVideoUrl: brandBrief?.overviewVideoUrl || '',
      TargetAudience: brandBrief?.TargetAudience || '',
      maybes: '',
      commonRejectionReasons: '',
      goodPractices: '',
      badPractices: '',
      goodPracticesSecondary: brandBrief?.goodPractices || [],
      badPracticesSecondary: brandBrief?.badPractices || [],
      maybesSecondary: brandBrief?.maybes || [],
      commonRejectionSecondary: brandBrief?.commonRejectionReasons || [],
    },
    mode: 'onSubmit',
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onPracticeAppend = (sourceField: string, value: string) => {
    setPracticesState((prev) => ({
      ...prev,
      [sourceField]: [...new Set([...(prev?.[sourceField] || []), value])],
    }));
  };

  const onPracticeRemove = (sourceField: string, filteredValues: string[]) => {
    setPracticesState((prev) => ({
      ...prev,
      [sourceField]: filteredValues,
    }));
  };

  const toggleNextButtonState = () => {
    setIsButtonDisabled((prev) => !prev);
  };

  const isFormError = !!Object.values(errors).length;
  const onTabChange = (_, newTab) => {
    setSelectedTab(newTab);
  };

  const onSubmit = useCallback(
    async (data) => {
      if (!brandBrief?.id) {
        return;
      }
      const inputData = {
        id: brandBrief.id,
        overview: data.overview,
        messaging: data.messaging,
        active: data.active,
        goodPractices: data?.goodPracticesSecondary || [],
        badPractices: data?.badPracticesSecondary || [],
        BriefName: data.BriefName,
        objective: data.objective,
        TargetAudience: data.TargetAudience, // Ensure the key matches
        country: data.country,
        tone: data.tone,
        overviewVideoUrl: data.overviewVideoUrl,
        tags: data.tags,
        commonRejectionReasons: data?.commonRejectionSecondary || [],
        maybes: data?.maybesSecondary || [],
      };

      await updateBrief({
        variables: {
          input: inputData,
        },
      });

      if (!isFormError) {
        stageNext();
      }
    },
    [brandBrief?.id, isFormError, stageNext, updateBrief, practicesState]
  );

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col">
        <TabContext value={selectedTab}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <TabList
              TabIndicatorProps={{ className: 'bg-main-teal' }}
              onChange={onTabChange}
            >
              <Tab
                disableRipple={true}
                className={`${selectedTab === Tabs.Activation
                  ? 'text-[#00B1B5]'
                  : 'text-[black]'
                  } head-text font-[700] border-b-2 border-b-black border-solid`}
                value={Tabs.Activation}
                label={Tabs.Activation}
              ></Tab>

              <Tab
                disableRipple={true}
                className={`${selectedTab === Tabs.Access
                  ? 'text-[#00B1B5]'
                  : 'text-[black]'
                  } head-text font-[700] border-b-2 border-b-black border-solid`}
                value={Tabs.Access}
                label={Tabs.Access}
              ></Tab>
            </TabList>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)}>
            <TabPanel value={Tabs.Activation}>
              <MainTab
                toggleNextButton={toggleNextButtonState}
                practicesState={practicesState}
                onPracticeRemove={onPracticeRemove}
                onPracticeAppend={onPracticeAppend}
                brandBrief={brandBrief}
              />
            </TabPanel>
          </form>

          <TabPanel value={Tabs.Access}>
            <AdminBriefsAccess brandBrief={brandBrief} />
          </TabPanel>
        </TabContext>

        {selectedTab !== Tabs.Access && (
          <Button
            onClick={handleSubmit(onSubmit)}
            disableRipple={true}
            disabled={isFormError || updateBriefLoading || isButtonDisabled}
            className={classNames(
              'bg-main-black text-white px-[22px] py-[8px] rounded-[16px] text-[12px] disabled:bg-neutral-400 break-keep text-wrap self-center',
              {
                'bg-gray-600': isFormError,
              }
            )}
          >
            <p className="flex justify-evenly uppercase font-semibold items-center gap-x-1">
              Next
            </p>
          </Button>
        )}
      </div>
    </FormProvider>
  );
};

export default withAdmin(FirstPage);
