import { BrandBrief } from 'API';
import { TileTitles } from 'pages/CreatorBriefDetails/constants';
import { FC } from 'react';
import CreatorTile from '../CreatorTile/CreatorTile';

interface IBriefDetailsHeaderProps {
  brandBrief: BrandBrief;
}
const placement = "top"
const BriefDetailsHeader: FC<IBriefDetailsHeaderProps> = ({
  brandBrief,
}) => (

  <>
    <CreatorTile
      title={TileTitles.BrandName}
      subtitle={brandBrief?.brandProfile?.userProfile?.name}
    />

    <CreatorTile
      title={TileTitles.BriefName}
      subtitle={brandBrief?.BriefName}
    />

    <CreatorTile
      title="Objective"
      subtitle="Brand Awareness"
      tooltip={"Awareness/Reach: Building visibility for the brand and getting it in front of as many people as possible. \n Consideration: Encouraging audiences to learn more, engage, or show interest in the product or service. \n Conversion: Driving specific actions, like purchases, sign-ups, or downloads."}
      tooltipId="objective-tooltip"
      shouldEdit={true}
    />

    <CreatorTile
      title={TileTitles.TargetAudience}
      subtitle={brandBrief?.TargetAudience || ""}
      tooltip="The target audience is the specific group of people the brand and activation is designed to reach & resonate with."
      tooltipId="TargetAudience-tooltip"
      placement={placement}
      shouldEdit={false}
    />
  </>
);

export default BriefDetailsHeader;
