import { CircularProgress } from '@mui/material';
import { BrandBrief, WhitelistEntry } from 'API';
import { StyledTextField } from 'components/TextField';
import { UseGetBrandBriefById, UseUpdateBrandBriefWhitelist } from 'hooks';
import { UpdateBrandBrief } from 'hooks/query/useAdminActions';
import { ENTER_KEY_CODE } from 'hooks/utils';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { isEmail } from 'utils/utils';
import AccessSettings from './AccessSettings/AccessSettings';
import { ErrorMessages, SuccessMessages } from './constants';
import { EmailDisplay } from './EmailDisplay/EmailDisplay';
import RemoveModal from './RemoveModal/RemoveModal';

interface IProps {
  brandBrief?: BrandBrief | null;
  getBrandBriefs?: () => Promise<void>;
}

export const AdminBriefsAccess = ({ brandBrief, getBrandBriefs }: IProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedWhitelistEntry, setSelectedWhitelistEntry] =
    useState<WhitelistEntry | null>(null);
  const [entries, setEntries] = useState<
    Array<Omit<WhitelistEntry, '__typename'>>
  >([]);

  const { updateWhitelist, loading: updateLoading } =
    UseUpdateBrandBriefWhitelist();
  const { updateBrief } = UpdateBrandBrief();
  const { getBrief, data: updatedBrandBrief, loading } = UseGetBrandBriefById();

  const onReset = () => {
    setIsRemoveModalOpen(false);
    setSelectedWhitelistEntry(null);
    setIsSettingsDialogOpen(false);
  };

  const onRemoveEntry = useCallback(async () => {
    if (!brandBrief?.id || !selectedWhitelistEntry) {
      toast.error(ErrorMessages.InvalidEntry);
      return;
    }

    const updatedEntries = entries
      .filter(({ email }) => email !== selectedWhitelistEntry?.email)
      // @ts-expect-error
      .map(({ __typename, ...e }) => e);

    await updateBrief({
      variables: {
        input: {
          id: brandBrief.id,
          whitelist: updatedEntries,
        },
      },
      errorPolicy: 'ignore',
      ignoreResults: true,
    });

    setEntries(updatedEntries);
    onReset();
    onSubmit(updatedEntries, true)

  }, [brandBrief?.id, selectedWhitelistEntry, entries, updateBrief]);

  const addEntry = () => {
    const value = inputValue.trim();

    if (!value) return;

    if (!isEmail(value)) {
      toast.error(ErrorMessages.InvalidEntry);
      return;
    }

    // Check for duplicates
    const isDuplicate = entries.some(({ email }) => email.toLowerCase() === value.toLowerCase());
    if (isDuplicate) {
      toast.error(ErrorMessages.DuplicateEntry);
      return;
    }

    const updatedEntries = [
      ...entries,
      { email: value.toLowerCase(), isApprover: false },
    ];

    setInputValue('');
    setEntries(updatedEntries);

    // Trigger onSubmit with updated entries
    onSubmit(updatedEntries);
  };

  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY_CODE) {
      event.preventDefault();
      addEntry();
    }
  };

  const onOpenRemoveModal = (entry: WhitelistEntry) => {
    setIsRemoveModalOpen(true);
    setSelectedWhitelistEntry(entry);
  };

  const onCancelRemoveEntry = () => {
    setIsRemoveModalOpen(false);
    setSelectedWhitelistEntry(null);
  };

  const onSubmit = useCallback(async (entries?:
    Array<Omit<WhitelistEntry, '__typename'>>, isRemove?: boolean) => {
    if (!entries?.length || !brandBrief?.id) {
      return;
    }
    await updateWhitelist({
      variables: {
        brandBriefId: brandBrief.id,
        entries: entries.map((e) => ({
          email: e.email,
          isApprover: e.isApprover,
        })),
      },
    });

    if (isRemove) {
      toast.success(SuccessMessages.Removed);
    } else {
      toast.success(SuccessMessages.Updated);
    }

    if (isSettingsDialogOpen) {
      setIsSettingsDialogOpen(false)
    }

  }, [entries, brandBrief]);


  const onSettings = (entry: { email: string; isApprover: boolean }) => {
    setSelectedWhitelistEntry(entry as WhitelistEntry);
    setIsSettingsDialogOpen(true);
  };

  const onAccessSettingsChange = useCallback(() => {
    if (!selectedWhitelistEntry) {
      return;
    }

    const updatedEntry = {
      email: selectedWhitelistEntry.email,
      isApprover: !selectedWhitelistEntry.isApprover,
    };

    setSelectedWhitelistEntry(updatedEntry as WhitelistEntry);
    setEntries((prev) =>
      prev
        .filter((entry) => entry.email !== selectedWhitelistEntry.email)
        .concat([updatedEntry])
    );
  }, [selectedWhitelistEntry]);

  const onCloseAccessSettings = () => {
    setIsSettingsDialogOpen(false);
  };

  useEffect(() => {
    const briefId = brandBrief?.id

    if (!briefId) {
      return
    }
    getBrief({ variables: { id: briefId } });
  }, []);

  useEffect(() => {
    const briefId = brandBrief?.id;

    if (!briefId) {
      return;
    }

    getBrief({ variables: { id: briefId } });
  }, []);

  useEffect(() => {
    if (!updatedBrandBrief) {
      return;
    }

    setIsLoading(false);
    setEntries(updatedBrandBrief.whitelist ?? []); // Ensures a fallback empty array
  }, [updatedBrandBrief]);


  return (
    <div className="w-full">
      <div className="flex flex-col gap-y-8 w-full">
        <div className="w-full max-w-[600px] flex items-center gap-x-2">
          <StyledTextField
            name="ids"
            label="Enter email address"
            type="text"
            fullWidth={true}
            value={inputValue}
            onKeyDown={onKeyDown}
            onChange={(e) => setInputValue(e.target.value)}
            InputProps={{ className: 'border-black' }}
          />
          <button
            className="bg-main-black text-white font-bold py-2 px-4 rounded-[14px] disabled:bg-neutral-400"
            onClick={addEntry}
            disabled={!inputValue.trim()}
          >
            Add
          </button>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center w-full h-full">
            <div className="relative" style={{ marginRight: '600px' }}>
              <CircularProgress />
            </div>
          </div>
        ) : (
          <div className="max-w-[600px] flex flex-col gap-y-6">
            <div className="flex flex-row gap-4 flex-wrap w-full max-w-[600px]">
              {entries.map((entry) => (
                <EmailDisplay
                  key={entry.email}
                  entry={entry as WhitelistEntry}
                  onDelete={onOpenRemoveModal}
                  onSettings={() => onSettings(entry)}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      {selectedWhitelistEntry && (
        <RemoveModal
          isModalOpen={isRemoveModalOpen}
          entry={selectedWhitelistEntry}
          onAccept={onRemoveEntry}
          onClose={onCancelRemoveEntry}
        />
      )}

      {isSettingsDialogOpen && selectedWhitelistEntry && (
        <AccessSettings
          loading={updateLoading}
          onSubmit={() => onSubmit(entries)}
          entry={selectedWhitelistEntry}
          onCancel={onCloseAccessSettings}
          onAccessSettingsChange={onAccessSettingsChange}
        />
      )}
    </div>
  );
};
